import { UserStatusEnum } from "@api/enums/user-status.enum";
import { UserTypeEnum } from "@api/enums/user-type.enum";
import { Button, Table, Tooltip } from "@hyperflake/react-ui-library";
import { EventBlocker } from "@hyperflake/react-ui-library/eventBlocker/index.js";
import RoleChip from "modules/shared/components/RoleChip/RoleChip";
import StatusChip from "modules/shared/components/StatusChip/StatusChip";
import { PageHeading, PageTemplate } from "modules/shared/features/content";
import { EditIcon } from "modules/shared/icons";
import useUserListData from "modules/users/hooks/useUserListData";
import React from "react";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
  // const { data, isLoading } = useUserListData();
  const navigate = useNavigate();

  const users = [
    {
      _id: "1",
      name: "John Doe",
      company: {
        name: "ABC Inc.",
        address: "123 Main St, City, Country",
      },
      phoneNumber: "123-456-7890",
      whatsappNumber: "123-456-7890",
      dp: "avatar1.jpg",
      dpUrl: "https://example.com/avatar1.jpg",
      roles: UserTypeEnum.BROKER,
      status: UserStatusEnum.ENABLED,
      createdAt: new Date("2023-01-01"),
      updatedAt: new Date("2023-01-02"),
    },
    {
      _id: "2",
      name: "Jane Smith",
      company: null,
      phoneNumber: "987-654-3210",
      dp: "avatar2.jpg",
      dpUrl: "https://example.com/avatar2.jpg",
      roles: UserTypeEnum.LISTER,
      status: UserStatusEnum.DISABLED,
      createdAt: new Date("2023-02-01"),
      updatedAt: new Date("2023-02-02"),
    },
    {
      _id: "3",
      name: "Mike Johnson",
      company: {
        name: "XYZ Corp.",
        address: "456 Oak Ave, Town, Country",
      },
      phoneNumber: "555-123-4567",
      dp: "avatar3.jpg",
      dpUrl: "https://example.com/avatar3.jpg",
      roles: UserTypeEnum.VIEWER,
      status: UserStatusEnum.DELETED,
      createdAt: new Date("2023-03-01"),
      updatedAt: new Date("2023-03-02"),
    },
  ];
  // console.log("data", data);

  return (
    <PageTemplate>
      <div className="flex justify-between">
        <PageHeading>Users</PageHeading>
        <Button onClick={() => navigate("/users/add-user")}>
          Add New User
        </Button>
      </div>
      <Table
        className="mt-6"
        borderStyle="divider"
        // hover
        isLoading={false}
        data={users}
        columns={[
          {
            header: "ID",
          },
          {
            header: "Name",
          },
          {
            header: "Phone Number",
          },
          {
            header: "Role",
          },
          {
            header: "Status",
          },
          {
            header: "Action",
          },
        ]}
        renderRow={(row) => (
          <Table.Row key={row._id} onClick={() => {}}>
            <Table.Cell>
              {
                <div
                  className="text-primary cursor-pointer"
                  // onClick={() => editUserDailog.show(row._id)}
                >
                  {row._id}
                </div>
              }
            </Table.Cell>
            <Table.Cell>{row.name}</Table.Cell>
            <Table.Cell>{row.phoneNumber}</Table.Cell>
            <Table.Cell>{<RoleChip status={row.roles} />}</Table.Cell>
            <Table.Cell>{<StatusChip status={row.status} />}</Table.Cell>
            <Table.Cell>
              <Table.Cell>
                <EventBlocker stopPropagation preventDefault>
                  <button className="p-1 text-grayscale-500" onClick={() => {}}>
                    <EditIcon />
                  </button>
                </EventBlocker>
              </Table.Cell>
              {
                <>
                  {/* <Dropdown placement="bottom-start" showArrow>
                                <DropdownTrigger className="w-4 h-4 rounded-full">
                                    <EllipsisVerticalIcon />
                                </DropdownTrigger>

                                <DropdownList className="min-w-[100px]">
                                    <DropdownItem onClick={() => editUserDailog.show(row._id)}>
                                        Edit
                                    </DropdownItem>

                                    <DropdownItem onClick={() => projectAccessDailog.show(row._id)}>
                                        Project Access
                                    </DropdownItem>
                                    {hasAdminPermission ? (
                                        <DropdownItem
                                            onClick={() => deleteUserDailog.show(row)}
                                            className="text-danger"
                                        >
                                            Delete
                                        </DropdownItem>
                                    ) : (
                                        <Tooltip
                                            placement="left"
                                            content={'Only Admins are authorized to delete user'}
                                        >
                                            <DropdownItem
                                                className="text-gray-300 cursor-not-allowed"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                Delete
                                            </DropdownItem>
                                        </Tooltip>
                                    )}
                                </DropdownList>
                            </Dropdown> */}

                  {/* <div className="grid gap-2 grid-cols-3">
                  <Tooltip placement="top" content={"Edit"}>
                    <button onClick={() => editUserDailog.show(row._id)}>
                      <EditIcon />
                    </button>
                  </Tooltip>

                  {hasPermission(PermissionEnum.USER_DELETE) ? (
                                    <Tooltip placement="top" content={'Delete'}>
                                        <button onClick={() => deleteUserDailog.show(row)}>
                                            <DeleteShapeIcon />
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement="left"
                                        content={'Only Admins are authorized to delete User'}
                                    >
                                        <button className="text-gray-300 cursor-not-allowed">
                                            <DeleteShapeIcon />
                                        </button>
                                    </Tooltip>
                                )}
                </div> */}
                </>
              }
            </Table.Cell>
          </Table.Row>
        )}
      />
    </PageTemplate>
  );
};

export default UsersList;
