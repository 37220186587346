// import { ClientStatusEnum, UserStatusEnum } from '@api/enum';
import { UserStatusEnum } from "@api/enums";
import { Chip } from "@hyperflake/react-ui-library";
import { useMemo } from "react";

interface StatusChipProps {
  status: UserStatusEnum;
}

const StatusChip = (props: StatusChipProps) => {
  const { status } = props;

  const color = useMemo(() => {
    let statusColor: "primary" | "warning" | "success" | "danger" | "secondary";

    switch (status) {
      case UserStatusEnum.ENABLED:
        statusColor = "success";
        break;
      case UserStatusEnum.DISABLED:
        statusColor = "warning";
        break;
      case UserStatusEnum.DELETED:
        statusColor = "danger";
        break;
      default:
        statusColor = "primary";
        break;
    }
    return statusColor;
  }, []);

  return <Chip label={status} variant={"outlined"} color={color} />;
};

export default StatusChip;
