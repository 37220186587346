import { ContainerLayout, ContentLayout } from '@hyperflake/react-ui-library';
import { AppNavbar, AppSidebar } from 'modules/shared/features/navigation';

import { Outlet } from 'react-router-dom';

const MainLayout = () => {
    return (
        <ContainerLayout>
            <AppNavbar />

            <AppSidebar />

            <ContentLayout className="ml-[var(--sidebar-width)]">
                <Outlet />
            </ContentLayout>
        </ContainerLayout>
    );
};

export default MainLayout;
