// import { UserType } from '@api/enum';
import { UserTypeEnum } from "@api/enums";
import { Chip } from "@hyperflake/react-ui-library";
import { useMemo } from "react";

interface RoleChipProps {
  status: UserTypeEnum;
}

const RoleChip = (props: RoleChipProps) => {
  const { status } = props;

  const color = useMemo(() => {
    let statusColor: "primary" | "warning" | "success" | "danger" | "secondary";

    switch (status) {
      case UserTypeEnum.BROKER:
        statusColor = "success";
        break;
      case UserTypeEnum.LISTER:
        statusColor = "primary";
        break;
      case UserTypeEnum.VIEWER:
        statusColor = "warning";
        break;
      default:
        statusColor = "primary";
        break;
    }
    return statusColor;
  }, []);

  return <Chip label={status} variant={"outlined"} color={color} />;
};

export default RoleChip;
