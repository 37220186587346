import { useAuth } from "@store/AuthContext";
import { jwtDecode } from "jwt-decode";
import { removeAuthTokenFromStorage } from "modules/shared/helpers/auth.utils";
import moment from "moment";
import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface AuthGuardProps {
  page: ReactElement;
}

const AuthGuard = (props: AuthGuardProps) => {
  const location = useLocation();

  const { token, logout } = useAuth();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const payload = jwtDecode(token);

  const isExpired = moment().isAfter(moment.unix(payload.exp));

  if (isExpired) {
    logout();

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.page;
};

export default AuthGuard;
