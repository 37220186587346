import { UserStatusEnum, UserTypeEnum } from "@api/enums";
import {
  Button,
  DatePicker,
  HelpText,
  Label,
} from "@hyperflake/react-ui-library";
import { ErrorMessage, Form, Formik } from "formik";
import {
  FormikInput,
  FormikSelect,
  FormikSwitch,
  RequiredMark,
} from "modules/shared/features/formik";
import { UploadIcon } from "modules/shared/icons";
import { statusOption, userTypeOption } from "modules/users/helpers/user.util";
import useUserAddForm from "modules/users/hooks/useUserAddForm";

const UserAddForm = () => {
  const userFormData = useUserAddForm();

  const handleSubmit = () => {};
  return (
    <Formik
      initialValues={userFormData.initialValues}
      //enableReinitialize
      //   validationSchema={addUserForm.schema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, isValidating }) => (
        <Form>
          <div className="flex flex-col items-start">
            <Label>User DP</Label>

            <div className="flex items-center gap-4 mt-2">
              <div className="relative w-20 h-20 bg-grayscale-200 rounded-full">
                {values.file && (
                  <>
                    <img
                      className="w-full h-full rounded-full "
                      src={URL.createObjectURL(values.file)}
                      alt=""
                    />
                    <div
                      className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                      onClick={() => setFieldValue("file", null)}
                    >
                      Remove
                    </div>
                  </>
                )}
              </div>

              <div className="relative">
                <Button variant="shaded" size="xs">
                  <UploadIcon className="cursor-pointer" /> Upload Image
                  <input
                    type="file"
                    className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                    onChange={(event) => {
                      const files = event.target.files;

                      if (files && files.length > 0) {
                        setFieldValue("file", files[0]);
                      }
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-4 ">
            {/* name */}
            <div className="">
              <Label>
                Name <RequiredMark />
              </Label>
              <FormikInput type="text" name="name" />
              <HelpText variant={"error"}>
                <ErrorMessage name="name" />
              </HelpText>
            </div>

            {/* phoneNumber */}
            <div className="">
              <Label>
                Phone Number <RequiredMark />
              </Label>
              <FormikInput type="number" name="phoneNumber" />
              <HelpText variant={"error"}>
                <ErrorMessage name="phoneNumber" />
              </HelpText>
            </div>
            {/* whatsapp Number */}
            <div className="">
              <Label>
                Whatsapp Number <RequiredMark />
              </Label>
              <FormikInput type="number" name="whatsappNumber" />
              <HelpText variant={"error"}>
                <ErrorMessage name="whatsappNumber" />
              </HelpText>
            </div>
            {/* Roles */}
            <div>
              <Label>
                Roles <RequiredMark />
              </Label>
              <div className="flex gap-2">
                <FormikSelect
                  name="roles"
                  options={userTypeOption}
                  // disabled={formik.values.pointedTo !== ''}
                />
              </div>
              <HelpText variant={"error"}>
                <ErrorMessage name="role" />
              </HelpText>
            </div>
            {/* status */}
            <div>
              <Label>
                Status <RequiredMark />
              </Label>
              <div className="flex gap-2">
                <FormikSelect
                  name="status"
                  options={statusOption}
                  // disabled={formik.values.pointedTo !== ''}
                />
              </div>
              <HelpText variant={"error"}>
                <ErrorMessage name="role" />
              </HelpText>
            </div>

            {/* company name */}
            <div className="">
              <Label>Company Name</Label>

              <FormikInput type="text" name="company.name" />
              <HelpText variant={"error"}>
                <ErrorMessage name="company.name" />
              </HelpText>
            </div>
            <div className="">
              <Label>Company Address</Label>

              <FormikInput type="text" name="company.address" />
              <HelpText variant={"error"}>
                <ErrorMessage name="company.address" />
              </HelpText>
            </div>
            {/* dob */}
            {/* <div className="">
                  <Label>Date of birth</Label>
                  <DatePicker
                      className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                      onChange={(val) => setFieldValue('dob', val)}
                      value={values.dob}
                      maxDate={new Date()}
                  />
                  
                  <HelpText variant={'error'}>
                      <ErrorMessage name="dob" />
                  </HelpText>
              </div> */}
            {/* mobile number */}
            {/* <div className="">
                  <Label>Phone Number</Label>
                  <PhoneDetails />
                  <HelpText variant={'error'}>
                      <ErrorMessage name="phone.number" />
                  </HelpText>
              </div> */}

            {/* primary color */}
            {/* <div className="">
                  <Label>Primary Color</Label>
                  <ColorPicker
                      value={values.preferences.primaryColor}
                      setColor={(event: ColorResult) => {
                          setFieldValue('preferences.primaryColor', event.hex.toUpperCase());
                      }}
                  />
              </div> */}
            {/* theme */}
            {/* <div className="mt-4">
                  <Label>
                      Theme <RequiredMark />
                  </Label>
                  <div className="flex gap-2">
                      <FormikSelect
                          name="preferences.theme"
                          options={themeOption}
                          disabled={formik.values.pointedTo !== ''}
                      />
                  </div>
                  <HelpText variant={'error'}>
                      <ErrorMessage name="preferences.theme" />
                  </HelpText>
              </div> */}
            {/* <div className="grid grid-cols-2">
              <div className="">
                <Label>Example</Label>
                <FormikSwitch
                  name="preferences.isEmailNotificationEnabled"

                  //   label="Email Notification"
                />
                <HelpText variant={"error"} />
              </div>
            </div> */}
          </div>

          <Button
            //   disabled={addUserForm.isSubmitting || isValidating}
            type="submit"
            size={"lg"}
            className="mt-8 w-28"
          >
            {/* {addUserForm.isSubmitting ? (
                  <CircularProgress size={20} color={'inherit'} />
              ) : isValidating ? (
                  'Validating'
              ) : (
                  'Submit'
              )} */}
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserAddForm;
