import { AuthContext } from "@store/AuthContext";
import { ILoginUser } from "@api/models/admin-user.model";
import {
  getAuthTokenFromStorage,
  removeAuthTokenFromStorage,
  setAuthTokenInStorage,
} from "modules/shared/helpers/auth.utils";
import { ReactNode, useState } from "react";
// import { IUserModel } from "../../../../models";

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>(getAuthTokenFromStorage());
  const [user, setUser] = useState<ILoginUser>(null);

  // useEffect(() => {
  //     const getLister = async () => {
  //         const { user } = await httpClient.get(${process.env.NEXT_PUBLIC_API_URL}/profile);
  //         setUser(user);
  //     };
  //     if (token) {
  //         getLister();
  //     }
  // }, [token]);

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //     setIsLoading(false);
  // }, [token]);

  const handleLogin = (token: string) => {
    setToken(token);
    setAuthTokenInStorage(token);
  };

  const handleLogout = () => {
    setToken("");
    removeAuthTokenFromStorage();
    setUser(null);
  };

  const handleSetUser = (user: ILoginUser) => {
    setUser(user);
  };

  // if (isLoading) return <h1>Loading</h1>;

  return (
    <AuthContext.Provider
      value={{
        token: token,
        user: user,
        login: handleLogin,
        logout: handleLogout,
        setUser: handleSetUser,
        isLoggedIn: Boolean(token),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
