import { UserStatusEnum, UserTypeEnum } from "@api/enums";
import React from "react";

const useUserAddForm = () => {
  const initialValues: any = {
    name: "",
    company: {
      name: "",
      address: "",
    },
    phoneNumber: "",
    whatsappNumber: "",
    dp: "",
    dpUrl: "",
    roles: UserTypeEnum.VIEWER,
    status: UserStatusEnum.ENABLED,
    file: "",
  };
  return {
    initialValues,
  };
};

export default useUserAddForm;
