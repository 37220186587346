import useComputedStyles from 'modules/shared/hooks/use-computed-styles';
import {
    DetailedHTMLProps,
    ForwardedRef,
    ImgHTMLAttributes,
    SyntheticEvent,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

/* -------------------------------------------------------------------------------------------------
 * useImgLoadingOrError
 * -----------------------------------------------------------------------------------------------*/

const useImgLoadingOrError = (src: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(src ? true : false);
    const [isError, setIsError] = useState<boolean>(false);

    const _isLoading = useMemo(() => {
        if (!src) return false;

        return isLoading;
    }, [src, isLoading]);

    const _isError = useMemo(() => {
        if (!src) return true;

        return isError;
    }, [src, isError]);

    return {
        isLoading: _isLoading,
        setIsLoading,
        isError: _isError,
        setIsError,
    };
};

/* -------------------------------------------------------------------------------------------------
 * Img
 * -----------------------------------------------------------------------------------------------*/

interface ImgProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    parentClassName?: string;
    ref?: ForwardedRef<HTMLImageElement>;
}

const Img = forwardRef((props: ImgProps, ref: ForwardedRef<HTMLImageElement>) => {
    const { parentClassName, src, onLoad, onError, ...rest } = props;

    const { refs, computedStyle } = useComputedStyles();

    useImperativeHandle(ref, () => refs.element.current, [refs.element]);

    const { isLoading, setIsLoading, isError, setIsError } = useImgLoadingOrError(src);

    const handleLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        setIsLoading(false);
        setIsError(false);

        onLoad?.(e);
    };

    const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        setIsLoading(false);
        setIsError(true);

        onError?.(e);
    };

    return (
        <div
            className={twMerge('relative overflow-hidden', parentClassName)}
            style={{
                borderRadius: computedStyle?.borderRadius,
                aspectRatio: computedStyle?.aspectRatio,
            }}
        >
            <img {...rest} ref={refs.setElement} alt="" src={src} onLoad={handleLoad} onError={handleError} />

            {isError && (
                <div
                    className="absolute inset-0 w-full h-full bg-grayscale-100 flex items-center justify-center"
                    style={{ borderRadius: 'inherit' }}
                >
                    <svg
                        width={'20%'}
                        viewBox="0 0 114 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ borderRadius: '4px' }}
                    >
                        <path
                            d="M113.959 2.548v64.838a1.632 1.632 0 01-1.631 1.632H2.261A1.63 1.63 0 01.63 67.386V2.548A1.632 1.632 0 012.26.916h110.067a1.63 1.63 0 011.632 1.631v.001z"
                            fill="#C8DDFA"
                        />
                        <path
                            d="M28.372 39.467c7.909 0 14.32-6.412 14.32-14.32 0-7.91-6.411-14.322-14.32-14.322-7.91 0-14.321 6.412-14.321 14.321 0 7.91 6.411 14.321 14.32 14.321z"
                            fill="#000"
                        />
                        <path
                            d="M73.282 69.278H3.028a1.435 1.435 0 01-.262-.02l33.227-57.555a2.327 2.327 0 014.05 0l22.299 38.624 1.068 1.848 9.873 17.103z"
                            fill="#8EBBFE"
                        />
                        <path
                            opacity={0.2}
                            d="M73.28 69.278H49.292l11.775-17.104.847-1.231.424-.617 1.068 1.849 9.872 17.103z"
                            fill="#000"
                        />
                        <path
                            d="M112.397 69.278H51.633l11.775-17.104.847-1.232L79.6 28.653c1.006-1.46 3.426-1.552 4.608-.275.08.087.154.179.221.275l27.969 40.625z"
                            fill="#8EBBFE"
                        />
                        <path
                            d="M31.104 46.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                            fill="#000"
                        />
                        <path
                            d="M33.182 45.3a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM44.327 46.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.7 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                            fill="#000"
                        />
                        <path
                            d="M46.401 45.568a2.075 2.075 0 00-2.076-1.93 2.075 2.075 0 00-2.075 1.93h4.151z"
                            fill="#000"
                        />
                        <path
                            d="M34.621 52.995s4.262-1.6 7.389 1.721M42.615 54.39s-.753-.352-.744 1.151M42.613 40.149s2.087-.721 3.583 1.01M29 41.035s1.509-1.602 3.644-.762"
                            stroke="#000"
                            strokeWidth={1.17275}
                            strokeMiterlimit={10}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M75.104 53.699c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.26-1.268-1.26-.7 0-1.268.565-1.268 1.26 0 .696.568 1.26 1.268 1.26z"
                            fill="#000"
                        />
                        <path
                            d="M77.182 52.3a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151zM88.327 53.965c.7 0 1.268-.564 1.268-1.26 0-.695-.568-1.259-1.268-1.259-.7 0-1.268.564-1.268 1.26 0 .695.567 1.26 1.268 1.26z"
                            fill="#000"
                        />
                        <path
                            d="M90.401 52.568a2.075 2.075 0 00-2.075-1.93 2.075 2.075 0 00-2.076 1.93h4.151z"
                            fill="#000"
                        />
                        <path
                            d="M78.621 59.995s4.262-1.6 7.389 1.721M86.615 61.39s-.753-.352-.744 1.151M86.613 47.149s2.087-.721 3.583 1.01M73 48.035s1.509-1.602 3.644-.762"
                            stroke="#000"
                            strokeWidth={1.17275}
                            strokeMiterlimit={10}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            )}

            {isLoading && (
                <div
                    className="absolute inset-0 w-full h-full bg-grayscale-100"
                    style={{ borderRadius: 'inherit' }}
                ></div>
            )}
        </div>
    );
});

export default Img;
