import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Navbar,
} from "@hyperflake/react-ui-library";
// import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
// import { authActions } from '@store/auth';
// import store, { TRootState } from '@store/index';
// import { profileAction } from '@store/profile';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const AppNavbar = () => {
  // const navigate = useNavigate();
  // const { pathname } = useLocation();

  // const { profile } = useSelector((state: TRootState) => state);
  // const { supportCounter, publicApiCounter } = useSupportCounterData();
  // const { hasPermission } = useAuthPermission();

  // const navItems = [
  //     {
  //         content: 'Clients',
  //         isActive: pathname.startsWith('/clients'),
  //         onClick: () => navigate('/clients'),
  //         permission: hasPermission(PermissionEnum.CLIENT_READ),
  //     },
  //     {
  //         content: 'All Cameras',
  //         isActive: pathname.startsWith('/cameras'),
  //         onClick: () => navigate('/cameras'),
  //         permission: hasPermission(PermissionEnum.RESELLER_CAMERA_READ),
  //     },
  //     {
  //         content: 'All Users',
  //         isActive: pathname.startsWith('/all-users'),
  //         onClick: () => navigate('/all-users'),
  //         permission: hasPermission(PermissionEnum.ALL_USERS_READ),
  //     },
  //     {
  //         content: 'Sign In Logs',
  //         isActive: pathname.startsWith('/sign-in-logs'),
  //         onClick: () => navigate('/sign-in-logs'),
  //         permission: hasPermission(PermissionEnum.SIGN_IN_LOGS_READ),
  //     },
  //     {
  //         content: 'Console User',
  //         isActive: pathname.startsWith('/console-users'),
  //         onClick: () => navigate('/console-users'),
  //         permission: hasPermission(PermissionEnum.CONSOLE_USER_READ),
  //     },
  //     {
  //         content: (
  //             <div className="relative flex items-center">
  //                 <div>Support</div>
  //                 {supportCounter?.count !== 0 && <NotificationBadge count={supportCounter?.count} />}
  //             </div>
  //         ),
  //         isActive: pathname.startsWith('/support-tickets'),
  //         onClick: () => navigate('/support-tickets'),
  //         permission: hasPermission(PermissionEnum.SUPPORT_READ),
  //     },
  //     {
  //         content: (
  //             <div className="relative flex items-center">
  //                 <div>Public Api Request</div>
  //                 {publicApiCounter?.count !== 0 && <NotificationBadge count={publicApiCounter?.count} />}
  //             </div>
  //         ),
  //         isActive: pathname.startsWith('/public-requests'),
  //         onClick: () => navigate('/public-requests'),
  //         permission: hasPermission(PermissionEnum.PUBLIC_API_REQUEST_READ),
  //     },
  // ];

  // <div className="fixed top-0 left-0 right-0 bg-card-color border-b border-separator-color-light z-[52] h-[var(--navbar-height)] px-6">
  //     <div className="relative flex items-center gap-6 h-full">
  //         {/* <Link to="/">
  //   <img
  //     className="h-8"
  //     src="/logos/logo-full-dark.svg"
  //     alt="Progress Center Logo"
  //   />
  // </Link> */}

  //         {/* <ul className="ml-10 flex gap-4 items-center">
  //             {navItems.map(
  //                 (row, i) =>
  //                     row.permission && (
  //                         <li
  //                             key={i}
  //                             className={cn(
  //                                 'text-sm font-medium text-grayscale-500 cursor-pointer hover:text-grayscale-600 px-1 py-1',
  //                                 row.isActive && 'text-primary hover:text-primary'
  //                             )}
  //                         >
  //                             <a onClick={row.onClick}>{row.content}</a>
  //                         </li>
  //                     )
  //             )}
  //         </ul> */}

  //         <div className="flex items-center gap-5">
  //             <Avatar
  //                 // src={profile.info.dp}
  //                 // alt={profile.info.name}
  //                 src="/svgs/one-kanal-logo.svg"
  //                 alt="UserName"

  //                 // size={40}
  //             />
  //             {/* <button>
  //                 <Avatar alt={profile.info.name} />
  //             </button> */}
  //         </div>
  //     </div>
  // </div>

  return (
    <Navbar className="px-4 z-[80]">
      <img
        className="h-8"
        src="/svgs/one-kanal-logo.svg"
        alt="Progress Center Logo"
      />

      <div className="ml-auto flex items-center gap-5">
        <Dropdown>
          <DropdownTrigger className="flex items-center gap-3">
            <Avatar
              src={""}
              alt={"Faizan"}

              // size={40}
            />
            {/* <DesktopMediaQuery>
                                <div>
                                    <div className="flex items-center gap-2">
                                        <div className="text-sm font-semibold">{user.name}</div>
                                        <div>
                                            <ChevronDownIcon strokeWidth={2} />
                                        </div>
                                    </div>
                                    <div className="text-grayscale-500 tracking-[0.8px] text-[0.65rem]">
                                        {user.role}
                                    </div>
                                </div>
                            </DesktopMediaQuery> */}
          </DropdownTrigger>
          <DropdownList>
            <DropdownItem onClick={() => console.log("show profile")}>
              Account
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                // store.dispatch(profileAction.removeProfile());
                // store.dispatch(authActions.logout());
                console.log("logout");
              }}
            >
              <span className="text-danger">Log out</span>{" "}
            </DropdownItem>
          </DropdownList>
        </Dropdown>
        {/* <button>
                        <Avatar alt={profile.info.name} />
                    </button> */}
      </div>
    </Navbar>
  );
};

export default AppNavbar;
