// import { PermissionEnum } from "@api/enum/permission.enum";
import { Sidebar } from "@hyperflake/react-ui-library";
// import { useState } from 'react';
// import useAuthPermission from "modules/shared/hooks/use-auth-permission";
import { useLocation, useNavigate } from "react-router-dom";

// interface NotificationBadgeProps {
//     count: number;
// }
// const NotificationBadge = (props: NotificationBadgeProps) => {
//     const { count } = props;

//     return (
//         <div className="text-[10px] bg-warning w-5 h-5 ml-[0.25rem] -mt-[0.8rem] rounded-full grid place-items-center text-warning-contrast font-semibold">
//             {count}
//         </div>
//     );
// };

const AppSidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const links = [
    {
      content: "Dashboard",
      isActive: pathname.startsWith("/dashboard"),
      onClick: () => navigate("/dashboard"),
      // permission: hasPermission(PermissionEnum.CLIENT_READ),
    },
    {
      content: "Users",
      isActive: pathname.startsWith("/users"),
      onClick: () => navigate("/users"),
      // permission: hasPermission(PermissionEnum.RESELLER_CAMERA_READ),
    },
  ];

  return (
    <Sidebar>
      {/* <Sidebar.Content>
                <img className="h-8" src="/logos/logo.svg" alt="" />
            </Sidebar.Content> */}

      <Sidebar.Nav>
        {links.map((row, i) => (
          <Sidebar.NavItem
            key={i}
            isActive={row.isActive}
            onClick={row.onClick}
          >
            {row.content}
          </Sidebar.NavItem>
        ))}
      </Sidebar.Nav>

      {/* <Sidebar.Nav className="mt-auto">
                <Sidebar.NavItem>Account</Sidebar.NavItem>
                <Sidebar.NavItem className="text-danger">Sign Out</Sidebar.NavItem>
            </Sidebar.Nav> */}
    </Sidebar>
  );
};

export default AppSidebar;
