import { UserStatusEnum, UserTypeEnum } from '@api/enums';
import capitalize from 'lodash.capitalize';
export const userTypeOption = Object.values(UserTypeEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const statusOption = Object.values(UserStatusEnum).map((status) => ({
    label: capitalize(status),
    value: status,
}));
