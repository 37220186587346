export const isBrowser = () => typeof window !== "undefined";

export const getAuthTokenFromStorage = () => {
  if (isBrowser()) return localStorage.getItem("auth_token") || "";
};

export const setAuthTokenInStorage = (token: string) => {
  if (isBrowser()) return localStorage.setItem("auth_token", token);
};

export const removeAuthTokenFromStorage = () => {
  if (isBrowser()) return localStorage.removeItem("auth_token");
};
